import React from 'react';
import {  BrowserRouter as Router,
  Switch,
  Route,
  Link } from "react-router-dom"
import SignUpPage from "./pages/Signup"

import './App.css';




function App() {
  
  return (
    
      <Router>
      

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          
          <Route path="/">
            <SignUpPage />
          </Route>
        </Switch>
      
    </Router>
  
  );
}

export default App;
