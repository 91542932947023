import logo from '../logo.svg';
import { makeStyles } from '@material-ui/core/styles';

import { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import {  TextField, Button } from "@material-ui/core"
const liffId = process.env.REACT_APP_LINE_LIFF_ID || "test";;

declare global {
  interface Window {
    liff?: any;
  }
}

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    itemMain:{
      flex:1,
      padding:"1em",
      paddingTop:"2em"
    },
    item:{
      flex:1
    },
  
    input:{
      width:"100%"
    },
    itemDiv:{
      padding:"1em",
    },
    logo:{
      width:150,
      height:150
    }
    
  }));

function App() {
    const [isLinked, setLinked] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const liff = window.liff;
    /*useEffect(() => {
      fetch(`https://us-central1-djjam-d7ae7.cloudfunctions.net/lineMembers/${lineID}`)
    }, [isLinked])*/
    useEffect(() => {
      if(liff && !isLoaded)
        liff.init({
          liffId: liffId,
        })
        .then(() => {
           console.log('liff init');
          setLoaded(true)
        })
      
    })

    useEffect(() => {
      if(isLoaded){
        redirectIfMobile();//make sure stuff login
        console.log('ok next')
        getUser().
        then(result => {
          if(result.status =='found')
          {
            setLinked(true)
          }
        })
        //
        if(isLinked){
          alert("Connected")
        }
      } 
    })

    const getUser = async () => {
      let profile = await liff.getProfile();  
      console.log('profile', profile);
      return fetch(`https://us-central1-djjam-d7ae7.cloudfunctions.net/lineMembers/${profile.userId}`).then(res => res.json())
    }

    const redirectIfMobile = ()=> {
      console.log('redirect if mobile')
          if (!liff.isLoggedIn()) 
            if(!liff.isInClient())
            {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                  window.location.href = `https://line.me/R/app/${liffId}` + window.location.search
                }else{
                    liff.login();
                }
            }
    }
            
    const classes = useStyles();
    return (
      <Grid container direction="row"  className={classes.root}  >
         <Grid className={classes.item} item xs={12} ></Grid>
         <Grid className={classes.itemMain} item xs={12} >
            <form>
              <Grid spacing={2} container direction="column" justify="center" alignItems="center">
                <Grid className={classes.itemDiv} spacing={2}>
                  <img className={classes.logo} src={logo} />
                </Grid>
                <TextField className={classes.input} required id="firstname" label="First Name" />
                <TextField className={classes.input} required id="surname" label="Surname" />
                <TextField className={classes.input} required id="email" label="Email" />
                <TextField className={classes.input} required id="phone" label="Phone" />
                <Grid className={classes.itemDiv} spacing={2}>
                  <Button variant="contained" color="primary">SIGN UP</Button>
                </Grid>
                
              </Grid>
              
              
            </form>
         </Grid>
         <Grid className={classes.item} item xs={12} ></Grid>
        
      </Grid>
      
    );
  }
  
  export default App;